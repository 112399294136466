<template>
  <!-- NAME[epic=采购] 其他出入库 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="20px"
    >
      <el-form-item label="" prop="depot_id">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="仓库"
        >
          <el-option
            v-for="(i, idx) in depotList"
            :key="idx"
            :value="i.id"
            :label="i.depot_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 经办人 -->
      <el-form-item label="" prop="operator_id">
        <el-select
          v-model="form.operator_id"
          clearable
          style="width: 140px"
          placeholder="经办人"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.id"
            :label="i.user_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 类型 -->
      <el-form-item label="" prop="bill_type">
        <el-select
          v-model="form.bill_type"
          clearable
          style="width: 140px"
          placeholder="类型"
        >
          <el-option
            v-for="(i, idx) in typeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 时间 -->
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <!-- 单号 -->
      <el-form-item label="" prop="bill_code">
        <el-input v-model="form.bill_code" placeholder="请输入单号"></el-input>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="" prop="bill_status">
        <el-select
          v-model="form.bill_status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 商品名称 -->
      <el-form-item label="" prop="keyword">
        <el-input
          v-model="form.keyword"
          placeholder="请输入商品名称或助记码"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="info" @click="handleReset">重置</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleAdd">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list" border>
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '单号'"
            class="underline"
            @click="handleCheck(0, row)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>
          <el-button
            v-if="row.bill_status == 2"
            type="text"
            @click.native.prevent="handleedit($index, row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="
              row.bill_status_text != '已审核' &&
              row.bill_status_text != '已作废'
            "
            v-show="row.bill_status !== 4"
            type="text"
            @click.native.prevent="handleReview($index, row)"
          >
            审核
          </el-button>
          <el-button
            v-if="row.bill_status_text != '已作废'"
            v-show="row.bill_status !== 4"
            type="text"
            @click.native.prevent="handleDelete($index, row)"
          >
            作废
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handlePrint($index, row)"
          >
            打印
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <check
      ref="check"
      :depot-list="depotList"
      :staff-list="staffList"
      :type-select="typeSelect"
      @refresh="handleQuery"
    ></check>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      :bill_type="printType"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
  </div>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import _ from 'lodash'
  import Check from './components/check'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  export default {
    name: 'OtherDepot',
    components: { Check, RuilangDisplayDialog },
    data() {
      return {
        time: [],
        loading: false,
        printType: null,
        data_id: null,
        form: {
          depot_id: '', // 仓库ID
          keyword: '', // 商品名称 简拼
          page: 1, // 页码  默认1
          limit: 10, // 每页几条 默认10
          bill_type: 6, // 类型 6其它入库 8其它出库
          bill_status: '', // 1待审2已审4已取消5已作废  默认0是全部
          add_id: '', // 制单人ID
          operator_id: '', // 经办人ID
          check_id: '', // 审核人ID
          bill_code: '', // 编码
          remerk: '', // 备注
          start_time: '', // 开始日期 如：2021-5-12
          end_time: '', // 结束日期 如：2021-5-20
        },
        depotList: [],
        staffList: [],
        typeSelect: [
          {
            id: 6,
            name: '其他入库',
          },
          {
            id: 8,
            name: '其他出库',
          },
        ],
        statusSelect: [
          {
            id: 1,
            name: '待审核',
          },
          {
            id: 2,
            name: '已审核',
          },
          {
            id: 4,
            name: '已取消',
          },
          {
            id: 5,
            name: '已作废',
          },
        ],
        list: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        checkList: [
          '单号',
          '类型',
          '仓库',
          '制单人',
          '制单时间',
          '审核人',
          '审核时间',
          '状态',
          '备注',
          '打印',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 2,
            label: '类型',
            prop: 'bill_type_text',
            width: '',
          },
          {
            order: 3,
            label: '仓库',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 4,
            label: '制单人',
            prop: 'add_name',
            width: '',
          },
          {
            order: 5,
            label: '制单时间',
            prop: 'create_at',
            width: '',
          },
          {
            order: 6,
            label: '审核人',
            prop: 'check_name',
            width: '',
          },
          {
            order: 7,
            label: '审核时间',
            prop: 'check_at',
            width: '',
          },
          {
            order: 8,
            label: '状态',
            prop: 'bill_status_text',
            width: '',
          },
          {
            order: 9,
            label: '备注',
            prop: 'remark',
            width: '',
          },
          {
            order: 10,
            label: '打印',
            prop: 'print_count',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      this.depotSelect()
      this.staffSelect()
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/depotAdmin/in-out/bill-list',
          this.form
        )
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      // 经办人(制单人下拉)
      staffSelect() {
        postAction('/purchaseAdmin/base/operator-list').then((r) => {
          console.log('r', r)
          this.staffList = r.data.rows
        })
      },
      // 仓库下拉
      depotSelect() {
        // postAction('/depotAdmin/order/depot', { pageSize: -1 })
        postAction('/baseAdmin/common/depot-list', { pageSize: -1 })
          .then((res) => {
            this.depotList = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.fetchData()
      },

      handleQuery() {
        this.handleCurrentChange(1)
      },
      handleReset() {
        this.time = []
        this.$refs.form.resetFields()
      },
      handleAdd() {
        this.$refs.check.type = 1
        this.$refs.check.showDialog = true
      },
      handleCheck(index, row) {
        this.$refs.check.type = 2
        this.$refs.check.id = row.id
        this.$refs.check.showDialog = true
      },
      handleReview(index, row) {
        this.$refs.check.type = 3
        this.$refs.check.id = row.id
        this.$refs.check.showDialog = true
      },
      handleedit(index, row) {
        this.$refs.check.type = 4
        this.$refs.check.id = row.id
        this.$refs.check.showDialog = true
      },
      handleDelete(index, row) {
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          postAction('/depotAdmin/in-out/scrap', {
            bill_id: row.id,
          }).then((r) => {
            this.$message.success('作废成功')
            this.fetchData()
          })
        })
      },
      handlePrint(index, row) {
        console.log('打印', row)
        if (row.bill_type_text == '其它入库') {
          this.printType = 'RK'
          this.data_id = row.id
          this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
        } else if (row.bill_type_text == '其它出库') {
          this.printType = 'CK'
          this.data_id = row.id
          this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
        } else {
          alert('参数错误')
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
