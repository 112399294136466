<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    fullscreen
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <div
        v-if="type == 2 && /-/.test(form.bill_code)"
        style="color: red; position: absolute; left: 300px; top: 25px"
      >
        <span>原单：{{ form.old_code }}</span>
        <span>修改人：{{ form.update_name }}</span>
        <span>修改时间：{{ form.update_time }}</span>
      </div>
      <el-form
        ref="form"
        :model="form"
        :disabled="type == 2"
        inline
        label-position="right"
        label-width="70px"
        label-suffix=":"
      >
        <el-form-item label="仓库" prop="depot_id">
          <el-select
            v-model="form.depot_id"
            clearable
            style="width: 140px"
            placeholder="仓库"
          >
            <el-option
              v-for="(i, idx) in depotList"
              :key="idx"
              :value="i.id"
              :label="i.depot_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="bill_type">
          <el-select
            v-model="form.bill_type"
            clearable
            style="width: 140px"
            placeholder="类型"
          >
            <el-option
              v-for="(i, idx) in typeSelect"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经办人" prop="operator_id">
          <el-select
            v-model="form.operator_id"
            clearable
            style="width: 140px"
            placeholder="经办人"
          >
            <el-option
              v-for="(i, idx) in staffList"
              :key="idx"
              :value="i.id"
              :label="i.user_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-input placeholder="将光标移至此处，使用扫码器添加商品"></el-input>
        </el-form-item>

        <!-- 表格 -->
        <el-table
          stripe
          :data="list"
          style="margin-top: 10px"
          show-summary
          :summary-method="tableSummary"
        >
          <!-- 序号 -->
          <el-table-column align="center" type="index" width="50">
            <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="index"
                    :label="item.label"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.label == '商品名称' ? '240' : ''"
            align="center"
          >
            <template #default="{ row, $index }">
              <!-- 类型 -->
              <div v-if="item.label == '类型'">
                <el-select
                  v-model="row.goods_type"
                  style="width: 100%"
                  placeholder="类型"
                >
                  <el-option
                    v-for="(i, idx) in goodsTypeSelect"
                    :key="idx"
                    :value="i.id"
                    :label="i.name"
                  ></el-option>
                </el-select>
              </div>
              <!-- 商品 -->
              <div v-else-if="item.label == '商品名称'">
                <goods-search
                  ref="goodsSearch"
                  :f-key="row.goods_name"
                  :depot-id="form.depot_id"
                  @add-rows="addRows($event, $index)"
                  @select-goods-all="selectGoods($event, row)"
                ></goods-search>
              </div>

              <!-- 单位 -->
              <div v-else-if="item.label == '单位'">
                <el-select
                  v-model="row.unit_id"
                  style="width: 100%"
                  placeholder="单位"
                  @change="unitChange($event, row)"
                >
                  <el-option
                    v-for="(i, idx) in row.arr_unit"
                    :key="idx"
                    :value="i.id"
                    :label="i.unit_name"
                  ></el-option>
                </el-select>
              </div>

              <!-- 价格 -->
              <div v-else-if="item.label == '价格'">
                <el-input
                  v-model="row.goods_price"
                  placeholder="价格"
                  onkeyup="value=value.replace(/[^\-?\d.]/g,'')"
                ></el-input>
              </div>

              <!-- 数量 -->
              <div v-else-if="item.label == '数量'">
                <el-input
                  v-model="row.goods_num"
                  placeholder="数量"
                  onkeyup="value=value.replace(/[^\-?\d.]/g,'')"
                ></el-input>
              </div>

              <!-- 金额 -->
              <div v-else-if="item.label == '金额'">
                <!-- <el-input
                  v-model="row.total_price"
                  placeholder="金额"
                ></el-input> -->
                {{
                  (Number(row.goods_num) || 0) *
                  ((Number(row.goods_price) || 0).toFixed(2) || 0)
                }}
              </div>

              <!-- 生产日期 -->
              <div v-else-if="item.label == '生产日期'">
                <el-date-picker
                  v-model="row.goods_date"
                  type="date"
                  placeholder="生产日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                ></el-date-picker>
              </div>

              <!-- 备注 -->
              <div v-else-if="item.label == '备注'">
                <el-input v-model="row.remark" placeholder="备注"></el-input>
              </div>

              <div v-else>
                {{ row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type != 2"
            prop=""
            align="center"
            label="操作"
            fixed="right"
            min-width="140px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleCopyRow($index, row)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDeleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-form-item label="" prop="">
          <el-checkbox
            v-model="form.auto_update_price"
            style="margin-left: 23px"
            :true-label="1"
            :false-label="0"
          >
            生成出入库单时重新计算商品成本
          </el-checkbox>
        </el-form-item> -->
        <div></div>
        <el-form-item label="备注:" prop="" style="margin-top: 10px">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 10px; text-align: right">
      <el-button v-if="type == 3" type="primary" @click="hanldeSubmit">
        审核
      </el-button>
      <el-button v-if="type == 1" type="primary" @click="hanldeSubmit">
        提交
      </el-button>
      <el-button v-if="type == 4" type="primary" @click="hanldeSubmit">
        保存
      </el-button>
      <!--      <el-button>打印</el-button>-->
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import _ from 'lodash'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    name: 'Check',
    components: { GoodsSearch },
    props: {
      depotList: {
        type: Array,
        default: () => [],
      },
      staffList: {
        type: Array,
        default: () => [],
      },
      typeSelect: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        type: 1, //1添加2查看3审核
        id: null,
        showDialog: false,
        // 类型下拉 1常2增
        goodsTypeSelect: [
          {
            id: 1,
            name: '常品',
          },
          {
            id: 2,
            name: '赠品',
          },
        ],
        form: {
          depot_id: '',
          bill_type: null,
          operator_id: '',
          //1 是 0否
          auto_update_price: 0,
        },
        list: [],
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '单位',
          '价格',
          '数量',
          '金额',
          '条形码',
          '生产日期',
          '单位换算',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '类型',
            prop: 'goods_type',
            width: '100',
          },
          {
            order: 2,
            label: '商品名称',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 3,
            label: '规格',
            prop: 'goods_spec',
            width: '120',
          },
          {
            order: 4,
            label: '单位',
            prop: 'goods_unit',
            width: '100',
          },
          {
            order: 5,
            label: '价格',
            prop: 'goods_price',
            width: '100',
          },
          {
            order: 6,
            label: '数量',
            prop: 'goods_num',
            width: '100',
          },
          {
            order: 7,
            label: '金额',
            prop: 'total_price',
            width: '100',
          },
          {
            order: 8,
            label: '条形码',
            prop: 'unit_code',
            width: '160',
          },
          {
            order: 9,
            label: '生产日期',
            prop: 'goods_date',
            width: '180',
          },
          {
            order: 10,
            label: '单位换算',
            prop: 'goods_convert',
            width: '160',
          },
          {
            order: 11,
            label: '备注',
            prop: 'remark',
            width: '180',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
      title() {
        if (this.type == 1) {
          return '添加其他出/入库单'
        } else if (this.type == 2) {
          return '查看其他出/入库单'
        } else if (this.type == 3) {
          return '审核其他出/入库单'
        } else if (this.type == 4) {
          return '编辑其他出/入库单'
        } else {
          return ''
        }
      },
    },
    watch: {
      showDialog(v) {
        if (v && this.type == 1) {
          this.form.bill_type = 6
        }
        if (v && this.type != 1) {
          this.fetchData()
        } else if (!v) {
          this.form = {
            depot_id: '',
            bill_type: '',
            operator_id: '',
            //1 是 0否
            auto_update_price: 1,
          }
          this.list = []
        } else if (this.type == 1) {
          this.addRow()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 获取详情  出入库详情
      async fetchData() {
        this.loading = true
        let { data } = await postAction('/depotAdmin/in-out/detail', {
          bill_id: this.id,
        })
        this.form = data.info
        this.list = data.goods
        if (this.type != 2) {
          this.addRow()
        }
        this.loading = false
      },
      hanldeSubmit() {
        let form = JSON.parse(JSON.stringify(this.form))
        let goods = JSON.parse(JSON.stringify(this.list))
        let goodsArr = []
        goods.forEach((i) => {
          if (i.goods_id > 0) {
            goodsArr.push(i)
          }
        })
        let endData = {
          is_admin: 1,
          ...form,
          goods: goodsArr.length == 0 ? '' : JSON.stringify(goodsArr),
        }
        let url =
          this.type == 1
            ? '/depotAdmin/in-out/add'
            : this.type == 4
            ? 'depotAdmin/in-out/update'
            : '/depotAdmin/in-out/check'
        postAction(url, endData).then((r) => {
          console.log('rr', r)
          this.$emit('refresh')
          this.$message.success('添加成功')
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
      handleCopyRow(index, row) {
        this.list.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      handleDeleteRow(index, row) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一行')
          this.list = []
          this.addRow()
        } else {
          this.list.splice(index, 1)
        }
      },
      addRow() {
        this.list.push({
          id: '', // '数据ID',
          goods_id: '', // '产品ID',
          goods_name: '', // '产品名称',
          goods_spec: '', // '产品规格',
          unit_id: '', // '单位ID',
          goods_unit: '', // '单位名称',
          goods_num: '', // '产品数量',
          apply_goods_num: '', // '申请数量',
          goods_price: '', // '价格',
          total_price: '', // '金额',
          remark: '', // '备注',
          goods_convert: '', // '单位换算',
          unit_code: '', // '条码',
          goods_type: '', // '类型 1常2增',
          goods_date: '', // '生产日期',
          arr_unit: '', // [],
        })
      },
      addRows(val, index) {
        console.log('addddd', val)
        val.forEach((i) => {
          this.$set(i, 'goods_num', '')
          this.$set(i, 'goods_type', 1)
          this.$set(i, 'remark', i.info)
          this.$set(i, 'goods_spec', i.specs)
          this.$set(i, 'goods_convert', i.unit_cv)
          this.$set(i, 'goods_date', i.goods_production_date)
          this.list.splice(index, 0, i)
        })
      },
      tableSummary(param) {
        let sums = []
        sums[0] = '合计'

        //  如果是查看
        if (this.type == 2) {
          sums[6] = this.form.total_num //数量
          sums[7] = this.form.total_amount //金额
          console.log('chakan ', sums)
          return sums
        } else {
          const { columns, data } = param
          // 需要合计的下标
          let i = [6]
          // let val = data.map((item) => {
          //   console.log(item)
          // })
          columns.forEach((col, idx) => {
            i.forEach((n) => {
              if (idx == n) {
                console.log(col.property)
                const val = data.map((item) => Number(item[col.property]) || 0)
                console.log(val)
                if (!val.every((value) => isNaN(value))) {
                  const a = val.reduce((total, num) => {
                    if (!isNaN(total)) {
                      return total + num
                    }
                  })
                  sums[n] = a.toFixed(2)
                }
              }
            })
            if (idx == 0) {
              sums[idx] = '合计'
            }
          })
          console.log('sss', sums)
          let s = 0
          this.list.forEach((i) => {
            s +=
              (Number(i.goods_num) || 0) *
              ((Number(i.goods_price) || 0).toFixed(2) || 0)
          })
          sums[7] = s
          return sums
        }
      },
      selectGoods(val, row) {
        console.log('selectgoodssss', val)
        if (val.goods_id > 0) {
          // row = { ...row, ...val }
          val.goods_spec = val.specs
          val.goods_type = 1
          val.goods_convert = val.unit_cv
          Object.assign(row, val)
          this.addRow()
        }
      },
      unitChange(v, row) {
        console.log('vv', v)
        row.arr_unit.forEach((i) => (i.unit_id = i.id))
        let price = row.arr_unit.filter((i) => i.unit_id == v)[0].sell_price
        let code = row.arr_unit.filter((i) => i.unit_id == v)[0].unit_code
        console.log('rr', price)
        row.unit_code = code
        row.goods_price = price
      },
    },
  }
</script>
<style lang="scss" scoped></style>
