var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        fullscreen: "",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _vm.type == 2 && /-/.test(_vm.form.bill_code)
            ? _c(
                "div",
                {
                  staticStyle: {
                    color: "red",
                    position: "absolute",
                    left: "300px",
                    top: "25px",
                  },
                },
                [
                  _c("span", [_vm._v("原单：" + _vm._s(_vm.form.old_code))]),
                  _c("span", [
                    _vm._v("修改人：" + _vm._s(_vm.form.update_name)),
                  ]),
                  _c("span", [
                    _vm._v("修改时间：" + _vm._s(_vm.form.update_time)),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                disabled: _vm.type == 2,
                inline: "",
                "label-position": "right",
                "label-width": "70px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "仓库", prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { clearable: "", placeholder: "仓库" },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.depotList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.depot_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "bill_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { clearable: "", placeholder: "类型" },
                      model: {
                        value: _vm.form.bill_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "bill_type", $$v)
                        },
                        expression: "form.bill_type",
                      },
                    },
                    _vm._l(_vm.typeSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经办人", prop: "operator_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { clearable: "", placeholder: "经办人" },
                      model: {
                        value: _vm.form.operator_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "operator_id", $$v)
                        },
                        expression: "form.operator_id",
                      },
                    },
                    _vm._l(_vm.staffList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.user_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "将光标移至此处，使用扫码器添加商品",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    stripe: "",
                    data: _vm.list,
                    "show-summary": "",
                    "summary-method": _vm.tableSummary,
                  },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", type: "index", width: "50" } },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "custom-table-checkbox",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.checkList,
                                    callback: function ($$v) {
                                      _vm.checkList = $$v
                                    },
                                    expression: "checkList",
                                  },
                                },
                                _vm._l(_vm.columns, function (item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { label: item.label },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [
                                  _c("vab-remix-icon", {
                                    attrs: { icon: "settings-line" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.label == "商品名称" ? "240" : "",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                item.label == "类型"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: { placeholder: "类型" },
                                            model: {
                                              value: row.goods_type,
                                              callback: function ($$v) {
                                                _vm.$set(row, "goods_type", $$v)
                                              },
                                              expression: "row.goods_type",
                                            },
                                          },
                                          _vm._l(
                                            _vm.goodsTypeSelect,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.id,
                                                  label: i.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.label == "商品名称"
                                  ? _c(
                                      "div",
                                      [
                                        _c("goods-search", {
                                          ref: "goodsSearch",
                                          refInFor: true,
                                          attrs: {
                                            "f-key": row.goods_name,
                                            "depot-id": _vm.form.depot_id,
                                          },
                                          on: {
                                            "add-rows": function ($event) {
                                              return _vm.addRows($event, $index)
                                            },
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoods(
                                                $event,
                                                row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "单位"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: { placeholder: "单位" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitChange(
                                                  $event,
                                                  row
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_id", $$v)
                                              },
                                              expression: "row.unit_id",
                                            },
                                          },
                                          _vm._l(
                                            row.arr_unit,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.id,
                                                  label: i.unit_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.label == "价格"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "价格",
                                            onkeyup:
                                              "value=value.replace(/[^\\-?\\d.]/g,'')",
                                          },
                                          model: {
                                            value: row.goods_price,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_price", $$v)
                                            },
                                            expression: "row.goods_price",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "数量"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "数量",
                                            onkeyup:
                                              "value=value.replace(/[^\\-?\\d.]/g,'')",
                                          },
                                          model: {
                                            value: row.goods_num,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_num", $$v)
                                            },
                                            expression: "row.goods_num",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "金额"
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (Number(row.goods_num) || 0) *
                                              ((
                                                Number(row.goods_price) || 0
                                              ).toFixed(2) || 0)
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : item.label == "生产日期"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "date",
                                            placeholder: "生产日期",
                                            format: "yyyy 年 MM 月 dd 日",
                                            "value-format": "yyyy-MM-dd",
                                          },
                                          model: {
                                            value: row.goods_date,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_date", $$v)
                                            },
                                            expression: "row.goods_date",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "备注"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "备注" },
                                          model: {
                                            value: row.remark,
                                            callback: function ($$v) {
                                              _vm.$set(row, "remark", $$v)
                                            },
                                            expression: "row.remark",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        " " + _vm._s(row[item.prop]) + " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _vm.type != 2
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "",
                          align: "center",
                          label: "操作",
                          fixed: "right",
                          "min-width": "140px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleCopyRow($index, row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDeleteRow(
                                            $index,
                                            row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          394107111
                        ),
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c("div"),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { label: "备注:", prop: "" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px", "text-align": "right" } },
        [
          _vm.type == 3
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.hanldeSubmit } },
                [_vm._v(" 审核 ")]
              )
            : _vm._e(),
          _vm.type == 1
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.hanldeSubmit } },
                [_vm._v(" 提交 ")]
              )
            : _vm._e(),
          _vm.type == 4
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.hanldeSubmit } },
                [_vm._v(" 保存 ")]
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }